<template id="candidates-start">
  <div class="text-box">
    <div class="game-card">
      <div class="row mb-3">
        <div class="col-12">
          <p class="candidates__title candidates__title--green text-center">
            Кого вы возьмете на работу
          </p>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <p class="candidates__text text-center">
            Попробуйте себя в роли HR.<br />
            Вам нужно закрыть три вакансии:<br />
            <b>{{ positions.join(", ") }}</b
            >.
          </p>
          <p class="candidates__text text-center">
            На каждую вакансию претендуют по два кандидата.<br />
            При прочих равных показателях Вы ориентируетесь на ЭИ-профили
            претендентов.<br />
            Кого бы Вы взяли на предложенные должности из каждой пары? Проверьте
            себя!
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div>
          <button
            class="btn btn-accent text-center w-100 justify-content-center"
            type="button"
            v-on:click="startGame"
          >
            Начать
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    positions: {
      default: [],
    },
  },
  setup(props, context) {
    const startGame = () => {
      context.emit("startGame");
    };

    return {
      startGame,
    };
  },
};
</script>

<style scoped>
.text-box {
  padding: 3rem 0;
}

@media (max-width: 768px) {
  .text-box {
    width: 100%;
    margin: 0;
    background-color: #edf2f8;
    box-shadow: none;
    border-radius: 0;
  }

  .candidates__title {
    color: black;
  }
}
</style>
