<template id="candidates-card-property">
  <div class="card-property">
    <div class="card-property__title">
      <span>{{ title }}</span>
      <span>{{ value }}</span>
    </div>
    <div class="card-property__chart">
      <div
        class="card-property__chart-value"
        :style="{ width: getChartWidth }"
      ></div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "0",
    },
    maxValue: {
      type: Number,
      default: 200,
    },
  },
  setup(props) {
    const getChartWidth = computed(
      () => (props.value * 100) / props.maxValue + "%"
    );
    return {
      getChartWidth,
    };
  },
};
</script>

<style scoped>
.card-property {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-family: inherit;
  color: #2d2f40;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.card-property:not(:last-child) {
  margin-bottom: 15px;
}

.card-property__title {
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  max-width: 70%;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding: 0px 5px 0px 0px;
}

@media (min-width: 768px) {
  .card-property__title {
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;

    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .card-property__title {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
}

.card-property__chart {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;

  position: relative;
  height: 20px;
  background-color: #dbdbe1;
  overflow: hidden;
}

@media (min-width: 768px) {
  .card-property__chart {
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
  }
}

@media (min-width: 992px) {
  .card-property__chart {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}

.card-property__chart-value {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;

  background-color: #55cb28;
}
</style>