<template id="candidates-finish">
  <div class="flex flex-column text-box">
    <div class="row mb-3">
      <div class="col-12">
        <p class="candidates__title text-center">Ваш результат</p>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div>
        <table class="table">
          <tr v-for="(item, index) in result" :key="index">
            <td>
              <span class="candidates__text">{{ item.POSITION }}</span>
            </td>
            <td>
              <span
                :class="[
                  'result-icon',
                  'result-icon--small',
                  item.IS_CORRECT
                    ? 'result-icon--correct'
                    : 'result-icon--fail',
                ]"
              ></span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div>
        <button
          v-on:click="endGame"
          class="btn btn-accent text-center w-100 justify-content-center"
          type="button"
        >
          Завершить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    result: {
      default: [],
    },
  },
  setup(props, context) {
    const endGame = () => context.emit("endGame");

    return { endGame };
  },
};
</script>

<style scoped>
.result-icon--correct {
  background-image: url("./../../assets/images/icons/icon-correct.svg");
}

.result-icon--fail {
  background-image: url("./../../assets/images/icons/icon-fail.svg");
}

.result-icon {
  display: inline-block;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.candidates__title {
  color: #2d2f40;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.2;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(182, 196, 214, 0.5);
  border-radius: 10px;
}

table {
  border-collapse: collapse;
}
.table td,
.table th {
  padding: 1rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

@media (min-width: 768px) {
  .result-icon {
    width: 70px;
    height: 70px;
  }
}

@media (min-width: 992px) {
  .result-icon {
    width: 80px;
    height: 80px;
  }
}

.result-icon--small {
  width: 30px;
  height: 30px;
}
</style>